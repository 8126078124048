import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Footer from '../components/footer/footer';

const JamesPage = () => {
  const {james} = createRef;
  const finnArray = [
    {
      key: 2,
      content: `James on syntynyt Englannissa ja muuttanut Suomeen 7 vuotta sitten tutustuakseen enemmän Suomalaisiin juuriinsa koska hän on puolisiksi Suomalainen, puoliksi Englantilainen. Hän puhuu sujuvasti Englantia sekä Suomea vaikkakin Englanti on vahvempi kieli hänellä. Hän nauttii tilasta ja rauhasta Suomalaisessa kulttuurissa ja metsien sekä luonnon läheisyydestä.`,
    },
    {
      key: 3,
      content: `James on aina nauttinut työskennellä ihmislähtöisillä aloilla joissa voi auttaa vähentämään kipua ja epämukavuutta ja missä hän voi hyötyä siitä, että auttaa parantamaan muiden elämänlaatua. Hän rakastaa luoda asiakassuhteita ja kuulla mikä on jokaiselle yksilöllisesti tärkeää ja tarjota niin yksilöllistä hoitoa kuin mahdollista. Hän uskoo, että moni fyysinen vaiva liittyy myös henkiseen hyvinvointiin ja vaikuttaa siten myös sosiaaliseen hyvinvointiin ja työkykyyn. Joten jokaista hoitoa tulisi lähestyä mahdollisemman luonnollisesti.`,
    },
    {
      key: 4,
      content: `Jamesilla on kokemusta terveysalalta ollessani työssä kliinisissä olosuhteissa avustavana hoitajana oppien erityistarpeista niin Englannissa kuin Suomessakin. Hän on Englannissa opiskellut toimintaterapeutiksi. Sen lisäksi taitoihin kuuluu kuntoutuksen ja hoitajan opinnot sekä koulutetun hierojan opinnot.`,
    },
    {
      key: 5,
      content: `James rakastaa oppia tietoa ihmisen abatomiasta ja siitä  kuinka ihmiskeho toimii  ja laittaakseen kaiken sen tietotaidon käyttöön oppiakseen hierontaa. Häntä erityisesti kiinnostaa mielenterveys ja sosiaalinen terveys sekä näiden asioiden vuorovaikutus keskenään. Hän etsii aina uusia tapoja kehittää taitojaan ja lisätä tietämystään. lisäkoulutusten avulla.`,
    },
    {
      key: 6,
      content: `James on valmistunut koulutetuksi hierojaksi Itä Helsingin hierojakoulusta 2022 ja on perustanut oman yrityksen.`,
    },
    {
      key: 7,
      content: `Vapaa-ajallaan Hän pelaa sulkapalloa kolmesti viikossa paikallisella clubilla, maastopyöräilee , kiipeilee, viettää aikaa ystävien kanssa ja käy pitkillä lenkeillä luonnossa. Häntä kiinnostaa myös scifi aiheiset elokuvat ja sarjat.`,
    },
    {
      key: 8,
      content: `James on valmiina laittamaan tietonsa, taitonsa ja osaamisensa käytäntöön auttaakseen asiakkaita pääsemään eroon kivusta, epämukavista tuntemuksista ja stressiä edistääkseen asiakaan fyysistä ja henkistä hyvinvointia.`,
    }
  ];
  
  return (
      <Layout>
        <title>James</title>
        <div className='site-wrapper'>
          <Navigation
            homeRoute={`/`}
            langLink={`EN`}
            langTitle={'Englanninkielinen sivu'}
            language={`/james-en`}
            home={`Kotisivu`}
          />
          <main className="new-page">
            <About 
              sectionTitle={`James`} 
              ref={james}
              aboutTextArray={finnArray}
            />
          </main>
          <Footer followMe={`Seuraa minua`} contactInfo={`Yhteystiedot`}/>
        </div>
      </Layout>
  )
}

export default JamesPage;